import React from 'react';
import Layout from '../components/Layout';
import Container from '../components/Container';
import PageTitle from '../components/PageTitle';
import { GatsbySeo, FAQJsonLd } from 'gatsby-plugin-next-seo';
import { graphql, StaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import Casinolist from '../components/Pikakasinot-list';
import casinolistStyle from '../components/Casinolist.module.css';

class Pikakasinot extends React.Component {
  //LOAD MORE
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      visible: 20,
      error: false,
    };
    this.loadMore = this.loadMore.bind(this);
    this.showLess = this.showLess.bind(this);
  }
  loadMore() {
    this.setState(prev => {
      return { visible: prev.visible + 20 };
    });
  }
  showLess() {
    this.setState(prev => {
      return { visible: 20 };
    });
  }

  setListeners() {
    var lisa = document.getElementsByClassName('tab-lisa');
    var bonus = document.getElementsByClassName('tab-bonus');
    var i;
    for (i = 0; i < lisa.length; i++) {
      if (lisa[i].dataset.listenerAdded) continue;
      lisa[i].addEventListener('click', function() {
        this.classList.toggle('active');
        if (
          this.parentNode.nextElementSibling.childNodes[0].style.display ===
          'block'
        ) {
          this.parentNode.nextElementSibling.childNodes[0].style.display =
            'none';
        } else {
          this.parentNode.nextElementSibling.childNodes[0].style.display =
            'block';
          this.parentNode.nextElementSibling.childNodes[1].style.display =
            'none';
          this.nextElementSibling.classList.remove('active');
        }
      });
      lisa[i].dataset.listenerAdded = 'true';
    }
    var j;
    for (j = 0; j < bonus.length; j++) {
      if (bonus[j].dataset.listenerAdded) continue;
      bonus[j].addEventListener('click', function() {
        this.classList.toggle('active');
        if (
          this.parentNode.nextElementSibling.childNodes[1].style.display ===
          'block'
        ) {
          this.parentNode.nextElementSibling.childNodes[1].style.display =
            'none';
        } else {
          this.parentNode.nextElementSibling.childNodes[1].style.display =
            'block';
          this.parentNode.nextElementSibling.childNodes[0].style.display =
            'none';
          this.previousElementSibling.classList.remove('active');
        }
      });
      bonus[j].dataset.listenerAdded = 'true';
    }
  }
  componentDidMount() {
    var links = document.links;
    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
      if (links[i].hostname != window.location.hostname) {
        links[i].target = '_blank';
        links[i].rel = 'noopener';
      }
    }

    this.setListeners();

    var talletus = document.getElementsByClassName('talletus');
    var o;
    for (o = 0; o < talletus.length; o++) {
      var trustly =
        "<img src='/images/maksu/trustly.webp' alt='trustly' class='lazyload'/>";
      var euteller =
        "<img src='/images/maksu/euteller.webp'  alt='euteller' class='lazyload'/>";
      var visa =
        "<img src='/images/maksu/visa.webp' alt='visa' class='lazyload'/>";
      var visaelectron =
        "<img src='/images/maksu/visa-electron.webp' alt='visaelectron' class='lazyload'/>";
      var skrill =
        "<img src='/images/maksu/skrill.webp' alt='skrill' class='lazyload'/>";
      var mastercard =
        "<img src='/images/maksu/mastercard.webp' alt='mastercard' class='lazyload'/>";
      var maestro =
        "<img src='/images/maksu/maestro.webp' alt='maestro' class='lazyload'/>";
      var neteller =
        "<img src='/images/maksu/neteller.webp' alt='neteller' class='lazyload'/>";
      var paysafecard =
        "<img src='/images/maksu/paysafecard.webp' alt='paysafecard' class='lazyload'/>";
      var zimpler =
        "<img src='/images/maksu/zimpler.webp' alt='zimpler' class='lazyload'/>";
      var brite =
        "<img src='/images/maksu/brite.webp' alt='brite' class='lazyload'/>";
      var str = talletus[o].innerHTML;

      var res = str
        .replace('Trustly', trustly)
        .replace('Euteller', euteller)
        .replace('Brite', brite)
        .replace('Visa', visa)
        .replace('Visa Electron', visaelectron)
        .replace('Skrill', skrill)
        .replace('Mastercard', mastercard)
        .replace('Maestro', maestro)
        .replace('Neteller', neteller)
        .replace('Paysafecard', paysafecard)
        .replace('Zimpler', zimpler);
      talletus[o].innerHTML = res;
    }
  }

  componentDidUpdate() {
    this.setListeners();
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            contentfulMoneysivujenOsiot(slug: { eq: "pikakasinot" }) {
              slug
              sivu
              ylosanTeksti {
                json
              }
              kasinolista {
                kasinonNimi
                kasinonListanimi
                bonus
                ilmaiskierrokset
                logonTiedostonimi
                listalogo {
                  title
                  file {
                    url
                  }
                }
                afflink
                lisatiedotpikakasino {
                  json
                }
                bonustiedotpikakasinot {
                  json
                }
              }
              alaosanTeksti {
                json
              }
              alaosanTeksti2 {
                json
              }
              alaosanTeksti3 {
                json
              }
            }
          }
        `}
        render={data => (
          <Layout>
            <GatsbySeo
              title="Pikakasinot | Päivitetty lista luotettavista pikakasinoista (2025)"
              description="Turvalliset ja luotettavat pikakasinot löydät helposti tältä sivulta. Katso päivitetty lista ja valitse haluamasi pikakasino!"
            />
            <FAQJsonLd
              questions={[
                {
                  question: 'Miten tunnistan pikakasinon?',
                  answer:
                    'Helpoiten pikakasinon tunnistaa tutkimalla kasinon kotisivuja. Mikäli kyseiseltä sivulta ei löydy rekisteröitymisnappulaa vaan "talleta" tai "pelaa heti", on kyseessä pikakasino. ',
                },
                {
                  question: 'Voinko pelata mobiililaitteella pikakasinolla?',
                  answer:
                    'Kyllä. Pelaaminen ja rahansiirrot hoituvat pikakasinoilla hyvin myös mobiililaitteilla.',
                },
                {
                  question: 'Tuleeko pikakasinolle talletuksesta lisämaksuja?',
                  answer:
                    'Ei. Pikakasinolle tallettamisesta ei kerry sinulle lisäkuluja.',
                },
                {
                  question: 'Onko pikakasinoilla talletus- tai nostorajoja?',
                  answer:
                    'Kyllä. Pikakasinoilla on rajoituksia talletussummiin ja nostoihin. Rajoitukset vaihtelevat kasinokohtaisesti.',
                },
                {
                  question:
                    'Kuinka nopeasti pikakasinot maksavat voitot tililleni?',
                  answer:
                    'Nopeimmillaan voittojen maksu tapahtuu muutamassa minuutissa. Nostoajat vaihtelevat kasinokohtaisesti. Yleissääntönä voidaan kuitenkin pitää, että pikakasinoilta saat voitot nopeammin kuin tavallisilta kasinoilta.',
                },
                {
                  question:
                    'Saako pikakasinoille bonuksia ja ilmaiskierroksia?',
                  answer:
                    'Kyllä saa. Varsinkin uutena asiakkaana pääset nauttimaan monista eduista joita pikakasinot anteliaasta jakavat. Lue lisää ajankohtaisista tarjouksista sivuiltamme.',
                },
                {
                  question: 'Mistä löydän parhaiten uudet pikakasinot 2025?',
                  answer:
                    'Uusia pikakasinoita tulee jatkuvasti markkinoille. Niitä listaavia sivustoja on myös monia. Yksi parhaista on kasinosivu.com.',
                },
                {
                  question: 'Sisältääkö listanne kaikki pikakasinot?',
                  answer:
                    'Ei. Pikasino-listamme ei sisällä aivan kaikkia pikakasinoita. Ainoastaan turvalliset ja luotettaviksi testatut pika-kasinot.',
                },
              ]}
            />
            <Container>
              <PageTitle>{data.contentfulMoneysivujenOsiot.sivu}</PageTitle>
              <div className="ylaosa">
                {documentToReactComponents(
                  data.contentfulMoneysivujenOsiot.ylosanTeksti.json,
                  {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ASSET]: node => (
                        <img
                          className="lazyload page-img"
                          data-src={`${node.data.target.fields.file['en-US'].url}`}
                          alt={node.data.target.fields.title['en-US']}
                        />
                      ),
                    },
                  }
                )}
              </div>
              <table>
                <tbody>
                  {data.contentfulMoneysivujenOsiot.kasinolista
                    .slice(0, this.state.visible)
                    .map(
                      ({
                        listalogo,
                        logonTiedostonimi,
                        kasinonListanimi,
                        bonus,
                        ilmaiskierrokset,
                        afflink,
                        lisatiedotpikakasino,
                        bonustiedotpikakasinot,
                      }) => (
                        <tr
                          className={casinolistStyle.casino}
                          key={kasinonListanimi}
                        >
                          <td className={casinolistStyle.top}>
                            <div className={casinolistStyle.logo}>
                              <img
                                data-src={`/images/logo/${logonTiedostonimi}`}
                                alt={listalogo.title}
                                className="lazyload"
                                width="120"
                                height="50"
                              />
                              <div className={casinolistStyle.name}>
                                <span className={casinolistStyle.title}>
                                  <b>{kasinonListanimi}</b>
                                </span>
                              </div>
                            </div>
                            <div className={casinolistStyle.content}>
                              <div className={casinolistStyle.contentBox}>
                                <div className={casinolistStyle.contentInfo}>
                                  <div className={casinolistStyle.offerItem}>
                                    <i>Bonukset:</i>
                                    <p>
                                      <b>{bonus}</b>
                                    </p>
                                  </div>
                                  <div className={casinolistStyle.offerItem}>
                                    <i>Ilmaiskierrokset:</i>
                                    <p>
                                      <b>{ilmaiskierrokset}</b>
                                    </p>
                                  </div>
                                </div>
                                <div className={casinolistStyle.contentBtn}>
                                  <a
                                    className={casinolistStyle.btn}
                                    href={afflink}
                                    target="_blank"
                                  >
                                    Kasinolle
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className={casinolistStyle.bottom}>
                            <div className={casinolistStyle.pikaexpand}>
                              <div
                                className={`${casinolistStyle.expandTabs} tab-lisa`}
                              >
                                <span>Lisätiedot</span>
                              </div>
                              <div
                                className={`${casinolistStyle.expandTabs} tab-bonus`}
                              >
                                <span>Bonustiedot</span>
                              </div>
                            </div>
                            <div className={casinolistStyle.expandContent}>
                              <div
                                className={`${casinolistStyle.expandInfo} lisa`}
                              >
                                {documentToReactComponents(
                                  lisatiedotpikakasino.json
                                )}
                              </div>
                              <div
                                className={`${casinolistStyle.expandInfo} bonus`}
                              >
                                {documentToReactComponents(
                                  bonustiedotpikakasinot.json
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
              <div className="loadBtn">
                {this.state.visible > 20 && (
                  <button onClick={this.showLess} type="button" id="showLess">
                    Vähemmän
                  </button>
                )}
                {this.state.visible <
                  data.contentfulMoneysivujenOsiot.kasinolista.length && (
                  <button onClick={this.loadMore} type="button" id="loadMore">
                    Näytä lisää
                  </button>
                )}
              </div>
              <div className="alaosa">
                <img
                  src="/images/pikakasinot.webp"
                  alt="Pikakasinot"
                  title="Pelaa ilman rekisteröitymistä"
                  class="lazyload"
                  className="wrapperImage"
                  width="300"
                  height="286"
                />
                {documentToReactComponents(
                  data.contentfulMoneysivujenOsiot.alaosanTeksti.json,
                  {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ASSET]: node => (
                        <img
                          className="lazyload page-img"
                          data-src={`${node.data.target.fields.file['en-US'].url}`}
                          alt={node.data.target.fields.title['en-US']}
                        />
                      ),
                    },
                  }
                )}
              </div>
              <div className="alaosa2">
                {documentToReactComponents(
                  data.contentfulMoneysivujenOsiot.alaosanTeksti2.json,
                  {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ASSET]: node => (
                        <img
                          className="lazyload page-img"
                          data-src={`${node.data.target.fields.file['en-US'].url}`}
                          alt={node.data.target.fields.title['en-US']}
                        />
                      ),
                    },
                  }
                )}
              </div>
              <div className="alaosa2">
                {documentToReactComponents(
                  data.contentfulMoneysivujenOsiot.alaosanTeksti3.json,
                  {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ASSET]: node => (
                        <img
                          className="lazyload page-img"
                          data-src={`${node.data.target.fields.file['en-US'].url}`}
                          alt={node.data.target.fields.title['en-US']}
                        />
                      ),
                    },
                  }
                )}
              </div>

              <div>
                <h3>Usein kysyttyä pikakasinoista (FAQ):</h3>
                <p>
                  Pikakasinot herättävät paljon kysymyksiä. Olemme keränneet
                  alle yleisimmät tiedustelut.
                </p>
                <div>
                  <div>
                    <h4>Miten tunnistan pikakasinon? </h4>
                  </div>
                  <p>
                    Helpoiten pikakasinon tunnistaa tutkimalla kasinon
                    kotisivuja. Mikäli kyseiseltä sivulta ei löydy
                    rekisteröitymisnappulaa vaan "talleta" tai "pelaa heti", on
                    kyseessä pikakasino.
                  </p>
                </div>
                <div>
                  <div>
                    <h4>Voinko pelata mobiililaitteella pikakasinolla?</h4>
                  </div>
                  <p>
                    Kyllä. Pelaaminen ja rahansiirrot hoituvat pikakasinoilla
                    hyvin myös mobiililaitteilla.
                  </p>
                </div>
                <div>
                  <div>
                    <h4>Tuleeko pikakasinolle talletuksesta lisämaksuja?</h4>
                  </div>
                  <p>
                    Ei. Pikakasinolle tallettamisesta ei kerry sinulle
                    lisäkuluja.
                  </p>
                </div>
                <div>
                  <div>
                    <h4>Onko pikakasinoilla talletus- tai nostorajoja? </h4>
                  </div>
                  <p>
                    Kyllä. Pikakasinoilla on rajoituksia talletussummiin ja
                    nostoihin. Rajoitukset vaihtelevat kasinokohtaisesti.
                  </p>
                </div>
                <div>
                  <div>
                    <h4>
                      Kuinka nopeasti pikakasinot maksavat voitot tililleni?{' '}
                    </h4>
                  </div>
                  <p>
                    Nopeimmillaan voittojen maksu tapahtuu muutamassa
                    minuutissa. Nostoajat vaihtelevat kasinokohtaisesti.
                    Yleissääntönä voidaan kuitenkin pitää, että pikakasinoilta
                    saat voitot nopeammin kuin tavallisilta kasinoilta.
                  </p>
                </div>
                <div>
                  <div>
                    <h4>Saako pikakasinoille bonuksia ja ilmaiskierroksia?</h4>
                  </div>
                  <p>
                    Kyllä saa. Varsinkin uutena asiakkaana pääset nauttimaan
                    monista eduista joita pikakasinot anteliaasta jakavat. Lue
                    lisää ajankohtaisista tarjouksista sivuiltamme.
                  </p>
                </div>
                <div>
                  <div>
                    <h4>Mistä löydän parhaiten uudet pikakasinot 2025? </h4>
                  </div>
                  <p>
                    Uusia pikakasinoita tulee jatkuvasti markkinoille. Niitä
                    listaavia sivustoja on myös monia. Yksi parhaista on
                    kasinosivu.com.
                  </p>
                </div>
                <div>
                  <div>
                    <h4>Sisältääkö listanne kaikki pikakasinot?</h4>
                  </div>
                  <p>
                    Ei. Pikasino-listamme ei sisällä aivan kaikkia
                    pikakasinoita. Ainoastaan turvalliset ja luotettaviksi
                    testatut pika-kasinot.
                  </p>
                </div>
              </div>
            </Container>
          </Layout>
        )}
      />
    );
  }
}

export default Pikakasinot;
